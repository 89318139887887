<template>
  <div class="hello">
    <h1>e2b.pl - skracacz linków</h1>
    
    <h3>Wpisz link, który chcesz skrócić</h3>
    <form @submit.prevent="submitForm">
      <div>
          <label for="link">Link:</label>
          <input 
            type="text" 
            id="link" 
            placeholder="Wpisz tutaj link do skrócenia" 
            v-model="form.link" 
            required
          >
      </div>
      <div>
          <button type="submit">Wyślij</button>
      </div>
    </form>
    
    <!-- Wyświetlanie skróconego linku lub komunikatu -->
    <div v-if="shortenedLink">
      <h4>Skrócony link:</h4>
      <p><a :href="shortenedLink" target="_blank">{{ shortenedLink }}</a></p>
    </div>
    
    <!-- Komunikat o błędzie -->
    <div v-if="error" class="error">
      <p>{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPage',
  data() {
    return {
      form: {
        link: ''
      },
      shortenedLink: null, // Skrócony link
      error: null // Komunikat o błędzie
    };
  },
  methods: {
    async submitForm() {
      // Weryfikacja, czy link jest poprawny
      if (!this.isValidUrl(this.form.link)) {
        this.error = 'Proszę podać prawidłowy URL.';
        this.shortenedLink = null;
        return;
      }

      // Resetowanie poprzedniego błędu
      this.error = null;

      try {
        // Wysłanie POST do lokalnego API
        const response = await fetch('https://api.e2b.pl/api/setUrl/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ url: this.form.link })
        });

        // Sprawdzenie odpowiedzi
        if (!response.ok) {
          throw new Error('Wystąpił problem z API.');
        }

        // Pobranie danych JSON
        const data = await response.json();

        // Zapisanie skróconego linku zwróconego przez API
        this.shortenedLink = `https://api.e2b.pl/u/${data.url}`;
      } catch (error) {
        // Obsługa błędów
        this.error = error.message || 'Wystąpił błąd podczas skracania linku.';
      }
    },

    // Funkcja do walidacji URL
    isValidUrl(url) {
      const pattern = new RegExp('^(https?://)?([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}(/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?$');
      return pattern.test(url);
    }
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
form {
  margin: 20px 0;
}
input {
  padding: 10px;
  width: 100%;
  max-width: 500px;
  margin-right: 10px;
  font-size: 14px;
}
button {
  padding: 10px 20px;
  background-color: #42b983;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
button:hover {
  background-color: #368e6e;
}

.error {
  color: red;
  margin-top: 20px;
}
</style>
